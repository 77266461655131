import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from './stores/useStores';
import { Switch, Route } from 'react-router-dom';
import LoadingOverlay from './components/LoadingOverlay';
import Layout from './components/Layout';
import Login from './components/login/Login';
import DiaryClearancePage from './components/diary_clearance/index';

function App() {
  const { userStore } = useStores();

  return (
    <>
      {userStore.loading ? (
        <Layout>
          <LoadingOverlay opacity={0.5} />
        </Layout>
      ) : (
        <Layout>
          {!userStore.loggedIn ? (
            <Switch>
              <Route component={Login} />
            </Switch>
          ) : (
            <Switch>
              <Route path='/diary-clearance/:id' component={DiaryClearancePage} />
              <Route path='/diary-clearance' component={DiaryClearancePage} />
            </Switch>
          )}
        </Layout>
      )}
    </>
  );
}

export default observer(App);
