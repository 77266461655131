import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown
} from '@fortawesome/free-solid-svg-icons';
import GlobalTableFilter from '../GlobalTableFilter';

function BlocksTable({ data }) {
  const columns = useMemo(
    () => [
      {
        Header: 'Paddock Name',
        accessor: 'Block_Name'
      },
      {
        Header: 'BPCs Paddock Number',
        accessor: 'Block_ID'
      },
      {
        Header: 'Crop',
        accessor: 'Crop'
      },
      {
        Header: 'Variety Code',
        accessor: 'Code'
      },
      {
        Header: 'Variety Name',
        accessor: 'variety.name'
      },
      {
        Header: 'Diary Status',
        accessor: 'block_clearance.block_clearance_type.name',
        Cell: ({ value }) => 
        value ? value : 'Not Set'
      },
      {
        Header: 'Intake Date',
        accessor: 'block_clearance.intake_date',
        Cell: ({ value }) =>
          value
            ? DateTime.fromISO(value).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY
              )
            : ''
      },
      {
        Header: 'Sprays',
        accessor: (row) => row.sprays.length
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Fragment>
      <GlobalTableFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        style={{
          marginBottom: '0.5rem',
          maxWidth: 500
        }}
      />
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {column.canSort ? (
                    <span className='px-2'>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </span>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
}

function Property({ property }) {
  const history = useHistory();
  return (
    <Fragment>
      <Row className='my-3'>
        <Col>
          <Button
            variant='outline-secondary'
            onClick={() => {
              history.push('/diary-clearance');
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row className='my-3'>
        <Col>
          <Row>
            <Col sm={4}>
              <b>BPCs Vendor No:</b>
            </Col>
            <Col sm={8}>{property.Orchard_Id}</Col>
          </Row>
          <Row>
            <Col sm={4}>
              <b>BPCs Vendor Name:</b>
            </Col>
            <Col sm={8}>{property.Orchard_Name}</Col>
          </Row>
          <Row>
            <Col sm={4}>
              <b>Owner Name:</b>
            </Col>
            <Col sm={8}>{property.Owner_Name}</Col>
          </Row>
          <Row>
            <Col sm={4}>
              <b>Region Name:</b>
            </Col>
            <Col sm={8}>{property.region.Region_Name}</Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <BlocksTable data={property.blocks} />
        </Col>
      </Row>
    </Fragment>
  );
}

Property.propTypes = {
  property: PropTypes.object.isRequired
};

export default observer(Property);
