import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/useStores';
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter
} from 'react-table';
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Spinner,
  FormControl
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
  faExternalLinkAlt,
  faAngleRight,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleDoubleLeft
} from '@fortawesome/free-solid-svg-icons';
import GlobalTableFilter from '../GlobalTableFilter';
import Property from './Property';

function PropertiesTable({ data }) {
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        Header: 'BPCs Vendor No',
        accessor: 'Orchard_Id'
      },
      {
        Header: 'BPCs Vendor Name',
        accessor: 'Orchard_Name'
      },
      {
        Header: 'Owner Name',
        accessor: 'Owner_Name'
      },
      {
        Header: 'Region Name',
        accessor: 'region.Region_Name'
      },
      {
        Header: "Paddock's",
        accessor: (row) => {
          return row.blocks.length;
        }
      },
      {
        id: 'edit',
        sortable: false,
        filterable: false,
        Cell: ({ row }) => (
          <Button
            type='button'
            variant='link'
            className='text-primary'
            onClick={() =>
              history.push(`/diary-clearance/${row.original.Orchard_Id}`)
            }
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </Button>
        )
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Fragment>
      <GlobalTableFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        style={{
          marginBottom: '0.5rem',
          maxWidth: 500
        }}
      />
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {column.canSort ? (
                    <span className='px-2'>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} />
                      )}
                    </span>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className='d-flex align-items-center justify-content-between my-3'>
        <div>
          <Button
            variant='link'
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </Button>{' '}
          <Button
            variant='link'
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>{' '}
          <Button
            variant='link'
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>{' '}
          <Button
            variant='link'
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </Button>{' '}
        </div>
        <div>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
        </div>
        <div>
          <FormControl
            as='select'
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </FormControl>
        </div>
      </div>
    </Fragment>
  );
}

function DiaryClearancePage() {
  const { userStore } = useStores();
  const { id } = useParams();

  const [properties, setProperties] = useState([]);
  const [loadingProperties, setLoadingProperties] = useState(false);

  useEffect(() => {
    setLoadingProperties(true);
    fetch(
      process.env.REACT_APP_SPRAYVIEW_API_URL +
        'api/orchard/all?with[]=blocks.blockClearance&with[]=blocks.variety&with[]=blocks.sprays',
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + userStore.bearerToken,
          SeasonalDatabase: process.env.REACT_APP_SEASONAL_DATABASE
        }
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) userStore.refresh();
        else throw Error(res.statusText);
      })
      .then((data) => {
        if (data) {
          setProperties(data);
        }
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.error(err);
      })
      .finally(() => {
        setLoadingProperties(false);
      });
  }, [userStore]);

  if (loadingProperties) {
    return (
      <Container>
        <Row className='text-center'>
          <Col>
            <h3 className='my-3'>Diary Clearance</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Spinner animation='border' variant='primary' />
          </Col>
        </Row>
      </Container>
    );
  } else {
    const selectedProperty =
      id === undefined
        ? null
        : properties.find((property) => property.Orchard_Id == id);
    return (
      <Container>
        <Row className='text-center'>
          <Col>
            <h3 className='my-3'>Diary Clearance</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            {selectedProperty ? (
              <Property property={selectedProperty} />
            ) : (
              <PropertiesTable data={properties} />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default observer(DiaryClearancePage);
