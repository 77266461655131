import React from 'react';
import { Spinner } from 'react-bootstrap';

function LoadingOverlay(props) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: props.color ? props.color : 'white',
        opacity: props.opacity ? props.opacity : 0.8
      }}
    >
      <Spinner animation='border' variant='primary' />
    </div>
  );
}

export default LoadingOverlay;
