import { observable, action, decorate, computed } from 'mobx';
import Cookies from 'js-cookie';

export default class UserStore {
  loading;
  user;
  bearerToken;
  refreshToken;

  constructor() {
    if (!this.user) {
      let cookieBearerToken = Cookies.get('bearerToken');
      let cookieRefreshToken = Cookies.get('refreshToken');
      let cookieRemember = Cookies.get('remember');

      if (cookieBearerToken) {
        if (cookieRemember) {
          this.login(cookieBearerToken, cookieRefreshToken, true);
        }
        // Page reload
        else if (window.performance) {
          if (performance.navigation.type === 1) {
            this.login(cookieBearerToken, cookieRefreshToken, cookieRemember);
          }
        }
      }
    }
  }

  login(bearerToken, refreshToken, remember) {
    this.loading = true;
    this.bearerToken = bearerToken;
    this.refreshToken = refreshToken;

    fetch(process.env.REACT_APP_AUTH_API_URL + 'api/user', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + bearerToken
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) this.refresh();
        else throw Error(res.statusText);
      })
      .then((data) => {
        if (data) {
          this.user = data;
          Cookies.set('bearerToken', bearerToken, { expires: 365 });
          Cookies.set('refreshToken', refreshToken, { expires: 365 });
          Cookies.set('remember', remember, { expires: 365 });
          this.loading = false;
        }
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.error(err);
        this.logout();
      });
  }

  refresh() {
    this.loading = true;

    fetch(process.env.REACT_APP_AUTH_API_URL + 'api/token/jwt/refresh', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        service: 'heinz_sprayview'
      },
      body: JSON.stringify({
        refresh_token: this.refreshToken
      })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error(res.statusText);
      })
      .then((data) => {
        if (data) {
          // User is already defined
          if (this.user) {
            this.loading = false;
            this.bearerToken = data.jwt_token;
            this.refreshToken = data.refresh_token;
          }

          // User is not defined
          else {
            this.login(
              data.jwt_token,
              data.refresh_token,
              Cookies.get('remember')
            );
          }
        }
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') console.error(err);
        this.logout();
      });
  }

  logout() {
    this.loading = false;
    this.user = undefined;
    this.bearerToken = undefined;
    this.refreshToken = undefined;
    Cookies.remove('bearerToken');
    Cookies.remove('refreshToken');
    Cookies.remove('remember');
  }

  get loggedIn() {
    return this.user ? true : false;
  }
}

decorate(UserStore, {
  loading: observable,
  user: observable,
  bearerToken: observable,
  refreshToken: observable,
  login: action,
  logout: action,
  loggedIn: computed
});
