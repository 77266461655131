import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './header.scss';

function Header() {
  return (
    <div className='header border-heinz border-bottom'>
      <div className='header_inner'>
        <div style={{ width: '200px' }}>
          <Link to='/home'>
            <img
              className='w-100'
              src='https://heinz-weighbridge-assets.s3-ap-southeast-2.amazonaws.com/logo.png'
              alt='Heinz Weigh Bridge Portal'
            />
          </Link>
        </div>
        <div className='h4'>Weigh Bridge</div>
        <div className='header_hortplus'>
          <a className='btn btn-link' href='https://www.heinz.com/'>
            Return to Heinz Website <FontAwesomeIcon icon={faArrowRight} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
