import React from 'react';
import Header from './header/Header';
import Navbar from './navbar/Navbar';
import Footer from './footer/Footer';
import './layout.scss';

function Layout(props) {
  return (
    <>
      <div id='page-content'>
        <Header />
        <Navbar />
        {props.children}
      </div>
      <Footer />
    </>
  );
}

export default Layout;
