import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/useStores';
import { Formik } from 'formik';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import LoadingOverlay from '../LoadingOverlay';

function Login() {
  const { userStore } = useStores();

  return (
    <Container>
      <Row className='text-center my-3'>
        <Col>
          <h3>Login</h3>
        </Col>
      </Row>
      <Row className='justify-content-center'>
        <Col md={6}>
          <Formik
            initialValues={{ email: '', password: '', remember: true }}
            onSubmit={(values, actions) => {
              fetch(
                process.env.REACT_APP_AUTH_API_URL + 'api/token/jwt/generate',
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    service: 'heinz_sprayview'
                  },
                  body: JSON.stringify({
                    email: values.email,
                    password: values.password
                  })
                }
              )
                .then((res) => {
                  if (res.ok) return res.json();
                  else throw Error(res.statusText);
                })
                .then((data) => {
                  if (data) {
                    userStore.login(
                      data.jwt_token,
                      data.refresh_token,
                      values.remember
                    );
                  }
                })
                .catch((err) => {
                  if (process.env.NODE_ENV === 'development')
                    console.error(err);
                  actions.setSubmitting(false);
                });
            }}
          >
            {(props) => (
              <Card>
                <Card.Body>
                  <Form onSubmit={props.handleSubmit}>
                    <Form.Group controlId='email'>
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type='email'
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.email}
                        placeholder='Enter your email address'
                        autoComplete='username'
                      />
                    </Form.Group>
                    <Form.Group controlId='password'>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type='password'
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.password}
                        placeholder='Password'
                        autoComplete='current-password'
                      />
                    </Form.Group>
                    <Form.Group controlId='remember'>
                      <Form.Check
                        type='checkbox'
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        checked={props.values.remember}
                        label='Remember me'
                      />
                    </Form.Group>

                    <div className='d-flex justify-content-center'>
                      <Button variant='primary' type='submit'>
                        Login
                      </Button>
                    </div>
                  </Form>
                  {props.isSubmitting ? <LoadingOverlay /> : null}
                </Card.Body>
              </Card>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(Login);
