import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/useStores';
import { useHistory, Link } from 'react-router-dom';
import { Nav, Navbar as BootstrapNavbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

function Navbar() {
  const { userStore } = useStores();
  let history = useHistory();

  return userStore.loggedIn ? (
    <BootstrapNavbar bg='light' expand='md'>
      <BootstrapNavbar.Toggle aria-controls='basic-navbar-nav' />
      <BootstrapNavbar.Collapse id='basic-navbar-nav'>
        <Nav className='m-auto'>
          <Nav.Link as={Link} to='/diary-clearance'>
            <FontAwesomeIcon icon={faHome} /> Diary Clearance
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              userStore.logout();
              history.push('');
            }}
          >
            Logout
          </Nav.Link>
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  ) : null;
}

export default observer(Navbar);
