import React from 'react';
import { Container } from 'react-bootstrap';
import './footer.scss';

function Footer() {
  return (
    <div id='footer' className='w-100 border-heinz border-top'>
      <Container>
        <div className='footer d-flex justify-content-center align-items-center'>
          Heinz weigh bridge portal by{' '}
          <a className='btn btn-link px-1' href='https://www.hortplus.com/'>
            HortPlus
          </a>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
